// MUI
import { Container, Stack, Typography } from "@mui/material";

// Media
import constraxLogo from "../../../../../assets/images/logos/constrix.png";

// Components
import PageHeader from "../PageHeader";
import AuthScreensIndex from "../../screens";
import { ParticlesComponent } from "../../../../../components/particles";
import RoundedBackgroundContainer from "../../../../../components/rounded-background-container";


export default function SignInEntryPoint() {
  return (
    <div
      className="flex justify-center items-center min-bs-[100dvh] is-full relative p-6"
      style={{ height: "100vh" }}
    >
      <img
        src={constraxLogo}
        alt="constrax"
        width={70}
        height={70}
        style={{
          color: "transparent",
          position: "absolute",
          left: "3%",
          top: "3%",
        }}
      />

      {/* Particles Background */}
      <div
        className="absolute w-full h-full top-0 left-0"
        style={{ zIndex: -2 }}
      >
        <ParticlesComponent />
      </div>

      {/* RoundedBackgroundContainer Top */}
      <div className="absolute top-0 w-full" style={{ height: 250 }}>
        <RoundedBackgroundContainer orientation="top">
          <Container maxWidth="xl" sx={{ py: 6 }}>
            <PageHeader />
          </Container>
        </RoundedBackgroundContainer>
      </div>

      {/* Page Content */}
      <AuthScreensIndex />

      {/* RoundedBackgroundContainer Bottom */}
      <div className="absolute bottom-0 w-full" style={{ height: 250 }}>
        <RoundedBackgroundContainer orientation="bottom">
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}
            mb={2}
          >
            <Typography variant="h6" textAlign="center" color="#ffffff">
              جميع الحقوق البرمجية محفوظة لشركة نيو فيجن التقنية
            </Typography>
            <img src={constraxLogo} style={{ height: "2em" }} alt="" />
          </Stack>
        </RoundedBackgroundContainer>
      </div>
    </div>
  );
}
