import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { api } from '../utils/requests/Api'
import { UserLookUpsType } from '../types/users/UserType'



const fetchData = async () => {
  const Response = await axios.get<UserLookUpsType>(api`lookup/create-user-lookup`)

  return Response.data
}

export default function useUserLookUpsData() {
  return useQuery({
    queryKey: [`user-lookup-data`],
    queryFn: fetchData
  })
}
