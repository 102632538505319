import { useQuery } from "@tanstack/react-query";
import { TenantType } from "../types/companies/companies";
import { api } from "../utils/requests/Api";
import axios from "axios";

const fetchData = async (params: string) => {
  // prepare helper methods
  const url = params.length > 0 ? `tenant?${params}` : `tenant`;
  const Response = await axios.get<{ tenants?: TenantType[] }>(api`${url}`);

  return Response.data.tenants;
};

export default function useCompaniesData(params: string) {
  return useQuery({
    queryKey: [`companies-data`, params],
    queryFn: () => fetchData(params),
  });
}
