import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChildrenPropsType } from "../types/GlobalTypes";
import { isAuthenticated } from "../utils/requests/isAuthenticated";

const NotAuthGuard: React.FC<ChildrenPropsType> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [navigate]);

  return <>{!isAuthenticated() ? children : null}</>;
};

export default NotAuthGuard;
