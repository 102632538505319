// import Hooks
import { useContext, useMemo, useState } from "react";

// MUI
import { Checkbox, Chip, Stack, Typography } from "@mui/material";

import { createColumnHelper } from "@tanstack/react-table";
import type { ColumnDef } from "@tanstack/react-table";

// Types
import { UserType } from "../../../../types/users/UserType";
import TableBuilder from "../../../../components/tables/TableBuilder";
import { UsersContext } from "../../context/UsersContext";
import SetUserDialog from "../set-user";
import ActionButton from "./components/ActionButton";

// define column helper that will help to create tanstack table columns
const columnHelper = createColumnHelper<UserType>();

export default function UsersDataTable() {
  // ** declare and define component state and variables
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const { users, refreshUserData, formMode, handleChangeFormMode } =
    useContext(UsersContext);

  // declare tanstack table columns
  const columns = useMemo<ColumnDef<UserType, any>[]>(
    () => [
      {
        id: "id",
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            {...{
              checked: row.getIsSelected(),

              // disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      columnHelper.accessor("name", {
        header: "الاسم",
        cell: ({ row }) => (
          <Typography color="text.primary">{row.original.name}</Typography>
        ),
        enableHiding: true, // Allow hiding this column
      }),
      // optionals
      {
        id: "title",
        header: "المسمى الوظيفي",
        cell: () => <Typography color="text.primary">_</Typography>,
      },
      columnHelper.accessor("email", {
        header: "البريد الالكتروني",
        cell: ({ row }) => (
          <Typography color="text.primary">{row.original.email}</Typography>
        ),
        enableHiding: true, // Allow hiding this column
      }),
      columnHelper.accessor("phone", {
        header: "رقم الجوال",
        cell: ({ row }) => (
          <Typography color="text.primary">{row.original.phone}</Typography>
        ),
        enableHiding: true, // Allow hiding this column
      }),
      {
        id: "branch",
        header: "الفرع",
        cell: () => <Typography color="text.primary">_</Typography>,
      },
      columnHelper.accessor("tenants", {
        header: "الشركة",
        cell: ({ row }) => {
          return (
            <Stack
              width={"100%"}
              direction={"row"}
              flexWrap={"wrap"}
              spacing={2}
            >
              {row.original.tenants?.map((ele) => (
                <Chip key={ele.id} label={ele.name} color="default" />
              ))}
            </Stack>
          );
        },
        enableHiding: true, // Allow hiding this column
      }),
      columnHelper.accessor("status", {
        header: "حالة الموظف",
        cell: ({ row }) => {
          if (row.original.status == -1)
            return <Chip label="استكمال بيانات" color="warning" />;

          return <Chip label="Status unknown" color="error" />;
        },
        enableHiding: true, // Allow hiding this column
      }),
      {
        id: "setting",
        header: "الأعدادات",
        cell: ({ row }) => {
          return (
            <ActionButton
              row={row.original}
              setOpenEditDialog={setOpenAddDialog}
              OnSuccessDeleteDialogAction={OnSuccessDeleteDialogAction}
            />
          );
        },
      },
    ],
    []
  );

  // ** declare and define component helper methods
  function durringFireAddFun() {
    handleChangeFormMode("Create");
  }
  const OnSuccessDeleteDialogAction = () => {
    refreshUserData();
  };

  // ** return component ui

  return (
    <>
      <TableBuilder
        data={users ?? []}
        columns={columns}
        addButtonLabel={` أضافة مستخدم`}
        exportButtonLabel="تصدير"
        globalFilterPlaceholder="بحث..."
        setOpenAddDialog={setOpenAddDialog}
        openAddDialog={openAddDialog}
        durringFireAddFun={durringFireAddFun}
        addDialogTitile={
          formMode === "Create" ? `أضافة مستخدم` : `تعديل مستخدم`
        }
        addDialogContent={
          <SetUserDialog open={openAddDialog} setOpen={setOpenAddDialog} />
        }
      />
    </>
  );
}
