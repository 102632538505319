import { Bounce, toast } from "react-toastify";

export function successMessage(statment: string) {
  toast.success(statment, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
    bodyStyle: {
      textAlign: "right",
      fontSize: "15px",
      fontWeight: 600,
    },
  });
}
