// MUI Imports
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";

// Type Imports
import { ThemeColor } from "../../types/GlobalTypes";

export type UserDataType = {
  title: string;
  stats: string;
  avatarIcon: JSX.Element;
  avatarColor?: ThemeColor;
  trend: string;
  trendNumber?: string;
  subtitle: string;
  cardWidth?: string;
};

const HorizontalWithSubtitle = (props: UserDataType) => {
  // Props
  const {
    title,
    stats,
    avatarIcon,
    avatarColor,
    trend: trend,
    trendNumber: trendNumber,
    cardWidth,
  } = props;

  // return component ui
  return (
    <Card sx={{ width: cardWidth ?? "275px", m: "1rem auto" }}>
      <CardContent className="flex h-full gap-2 align-middle">
        <Avatar variant="rounded" color={avatarColor}>
          {avatarIcon}
        </Avatar>
        <div className="flex flex-col  gap-1 flex-grow">
          <Typography variant="body2" fontSize={"1rem"}>
            {title}
          </Typography>
          <div className="flex items-center gap-2 flex-wrap">
            <Typography variant="body2" fontSize={"1rem"} color="text.primary">
              {stats}
            </Typography>
            {Boolean(trendNumber) && (
              <Typography
                variant="body2"
                fontSize={"0.7rem"}
                color={trend === "negative" ? "error.main" : "success.main"}
              >
                {`(${trendNumber}${trend === "negative" ? "-" : "+"})`}
              </Typography>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default HorizontalWithSubtitle;
