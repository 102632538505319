import { Typography } from "@mui/material";

export default function CenterDialogSuccessTtitle() {
  return (
    <Typography
      variant="body2"
      fontWeight={800}
      fontSize={22}
      my={4}
      textAlign={"center"}
    >
      تم تأكيد اضافة مستخدم بالرقم
    </Typography>
  );
}
