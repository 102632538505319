// Hooks
import { useContext, useState } from "react";

// MUI
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

// Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import getGlobalIdCookie from "../../../../../utils/cookies/getGlobalIdCookie";
import axios from "axios";
import getXtenantCookie from "../../../../../utils/cookies/getXtenantCookie";
import { api } from "../../../../../utils/requests/Api";
import { successMessage } from "../../../../../utils/notifications/successMessage";
import {
  AuthContext,
  AuthStatusEnum,
} from "../../../../../context/AuthContext";
import { errorMessage } from "../../../../../utils/notifications/errorMessage";

export default function ResetPasswordScreen() {
  // ** declare and define component state and variables
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { changeAuthStatus, setPasswordFirstTime, handleSetPasswordFirstTime } =
    useContext(AuthContext);

  // ** handle side effects

  // ** declare and define component helper methods
  const onSubmit = (data: FormData) => {
    const body = {
      global_id: getGlobalIdCookie(),
      password: data.password,
      password_confirmation: data.confirmPassword,
    };

    //start sending request
    axios
      .post(api`set-pass`, body, {
        headers: {
          "X-Tenant": getXtenantCookie(),
        },
      })
      .then(() => {
        successMessage(
          "تم تغير حفظ كلمة المرور بنجاح, سيتم تحويلك لتجسيل الدخول الأن"
        );
        handleSetPasswordFirstTime(false);
        changeAuthStatus(AuthStatusEnum.NotAuth);
      })
      .catch(() => {
        errorMessage("خطا غير متوقع");
      });
  };

  // ** component ui
  return (
    <Card className="flex flex-col ">
      {/* card header */}
      <CardHeader
        title={
          <Typography
            variant="body2"
            fontWeight={600}
            fontSize={26}
            textAlign={"center"}
          >
            {setPasswordFirstTime ? "اعادة " : ""}تعيين كلمة المرور
          </Typography>
        }
      ></CardHeader>
      {/* card content */}
      <CardContent className="p-6 sm:!p-12">
        <div className="flex flex-col gap-5">
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <div className="flex flex-col gap-4">
              {/* password */}
              <TextField
                autoFocus
                fullWidth
                size="small"
                type={showPassword ? "text" : "password"}
                label="كلمة المرور الجديدة"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword((prev) => !prev);
                        }}
                        edge="end"
                      >
                        {!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password")}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                onCopy={(e) => {
                  e.preventDefault();
                }}
                onPaste={(e) => {
                  e.preventDefault();
                }}
              />

              {/* confirm password */}
              <TextField
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                size="small"
                label="تأكيد كلمة المرور الجديدة"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowConfirmPassword((prev) => !prev);
                        }}
                        edge="end"
                      >
                        {!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("confirmPassword")}
                error={!!errors.confirmPassword}
                helperText={
                  errors.confirmPassword ? errors.confirmPassword.message : ""
                }
                onCopy={(e) => {
                  e.preventDefault();
                }}
                onPaste={(e) => {
                  e.preventDefault();
                }}
              />

              <Typography variant="body2" fontSize={15}>
                أن تكون كلمة المرور بطول 8 أحرف على الأقل، حرف كبير واحد على
                الأقل،
                <br /> استخدام رمز خاص واحد على الأقل.
              </Typography>
            </div>

            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={!isValid}
            >
              {!isSubmitting ? "تأكيد" : "جاري الحفظ"}
            </Button>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}

// Define schema with Zod
const schema = z
  .object({
    password: z
      .string()
      .regex(/[A-Z]/, { message: "لابد ان تحتوي علي حرف كبير علي الاقل" })
      .regex(/[a-z]/, { message: "لابد ان تحتوي علي حرف صغير علي الاقل" })
      .regex(/[0-9]/, { message: "لابد ان تحتوي رقم علي الاقل" })
      .regex(/[^A-Za-z0-9]/, {
        message: "لا بد ان تحتوي كلمة المرور علي رمز مثل #;.*@&",
      })
      .min(8, { message: "كلمة المرور لابد ان تكون 8 حروف على الأقل" }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "كلمتي المرور غير متطابقتين",
    path: ["confirmPassword"], // This will show the error on confirmPassword
  });

type FormData = z.infer<typeof schema>;
