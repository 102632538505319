import { useContext } from "react";
import UserIdentifier from "./01.UserIdentifier";
import ChooseSpecificTenant from "./02.ChooseTenant";
import EnterPasswordScreen from "./03.EnterPassword";
import ForgetPasswordScreen from "./04.ForgetPassword";
import ResetPasswordScreen from "./05.ResetPassword";
import { AuthContext, AuthStatusEnum } from "../../../../context/AuthContext";

export default function AuthScreensIndex() {
  // declare and define component state and variables
  const { authStatus } = useContext(AuthContext);
  console.log("REACT_APP_Encryption_KEY", Number(process.env.REACT_APP_Encryption_KEY));

  // declare and define component helper methods
  function RenderSuitableScreen() {
    switch (authStatus) {
      case AuthStatusEnum.NotAuth:
        return <UserIdentifier />;
      case AuthStatusEnum.ChooseTenant:
        return <ChooseSpecificTenant />;
      case AuthStatusEnum.Password:
        return <EnterPasswordScreen />;
      case AuthStatusEnum.ForgetPassword:
        return <ForgetPasswordScreen />;
      case AuthStatusEnum.ResetPassword:
        return <ResetPasswordScreen />;
    }
  }

  // return ui
  return <>{RenderSuitableScreen()}</>;
}
