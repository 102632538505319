import UsersEntryPoint from "./components/entryPoint";
import { UsersContextProvider } from "./context/UsersContext";

export default function UsersMainView() {
  return (
    <UsersContextProvider>
      <UsersEntryPoint />
    </UsersContextProvider>
  );
}
