import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/requests/Api";
import { CompaniesLookUpsType } from "../types/companies/companies";
import axios from "axios";

const fetchData = async (params: Record<string, unknown>) => {
  const url = `lookup/tenant/lookups`;
  const response = await axios.get<CompaniesLookUpsType>(api`${url}`, {
    params,
  });

  return response.data;
};

export default function useCompaniesLookups(params: Record<string, unknown>) {
  return useQuery({
    queryKey: ["companies-lookups-data", params],
    queryFn: () => fetchData(params),
  });
}
