// Hooks
import { useContext, useEffect, useState } from "react";

// MUI
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

// Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  AuthContext,
  AuthStatusEnum,
} from "../../../../../context/AuthContext";
import { api } from "../../../../../utils/requests/Api";
import getXtenantCookie from "../../../../../utils/cookies/getXtenantCookie";
import getGlobalIdCookie from "../../../../../utils/cookies/getGlobalIdCookie";
import axios from "axios";
import { errorMessage } from "../../../../../utils/notifications/errorMessage";

export default function ForgetPasswordScreen() {
  // ** declare and define component state and variables
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [timeLeft, setTimeLeft] = useState(900);
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { identifier, changeAuthStatus } = useContext(AuthContext);

  // ** handle side effects
  useEffect(() => {
    // If the timer reaches 0, stop the timer
    if (timeLeft <= 0) {
      setPasswordExpired(true);

      return;
    }

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [timeLeft]);

  // ** declare and define component helper methods
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  function handleSendPassword() {
    // declare helper variables
    const url = api`check-reset-password`;
    const body = { password_temp: password, global_id: getGlobalIdCookie() };

    const headers = {
      "Content-Type": "application/json",
      "X-Tenant": getXtenantCookie(),
    };

    // send request
    setLoading(true);
    axios
      .post(url, body, {
        headers: headers,
      })
      .then(() => {
        // check error message is exist ?
        changeAuthStatus(AuthStatusEnum.ResetPassword);
      })
      .catch((err) => {
        if (err?.status === 402)
          errorMessage("كلمة المرور غير صحيحة");
        else errorMessage("خطا غير متوقع برجاء المحاولة فى وقت اخر");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // ** component ui
  return (
    <Card className="flex flex-col sm:is-[460px]">
      {/* card header */}
      <CardHeader
        title={
          <Typography
            variant="body2"
            fontWeight={600}
            fontSize={20}
            textAlign={"center"}
          >
            نسيت كلمة المرور
          </Typography>
        }
      ></CardHeader>
      {/* card content */}
      <CardContent className="p-2">
        <div className="flex flex-col gap-3">
          <Typography variant="body2" fontSize={16}>
            ادخل كلمة المرور المؤقتة المرسلة على البريد الالكتروني
            <br />
            <span className="font-bold">{identifier}</span>
          </Typography>

          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleSendPassword();
            }}
            className="flex flex-col gap-5"
          >
            <TextField
              autoFocus
              fullWidth
              type={showPassword ? "text" : "password"}
              error={error}
              onChange={(e) => {
                if (e.target.value.length == 0) setError(true);
                else setError(false);

                setPassword(e.target.value);
              }}
              helperText={error ? "كلمة المرور المؤقتة مطلوبة" : ""}
              label="كلمة المرور المؤقتة"
              size="small"
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Typography variant="body2" fontWeight={500} fontSize={14}>
              تنتهي صلاحية كلمة المرور بعد {formatTime(timeLeft)} دقيقة
            </Typography>

            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={password.length == 0 || loading || passwordExpired}
            >
              {loading ? "جاري التنفيذ.." : "تأكيد"}
            </Button>

            <Button
              fullWidth
              variant="text"
              type="button"
              disabled={loading}
              sx={{
                textDecoration: "underline",
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => {}}
            >
              اعادة ارسال
            </Button>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}
