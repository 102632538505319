import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CompanyCard from "./CompanyCard";
import { useContext, useState } from "react";
import {
  AuthContext,
  AuthStatusEnum,
} from "../../../../../context/AuthContext";

export default function ChooseSpecificTenant() {
  // ** declare and define component state and variables
  const [shoeMore, setShoeMore] = useState(false);
  const { userTenants, changeAuthStatus } = useContext(AuthContext);
  const sliceLen = userTenants?.length > 3 ? 3 : userTenants?.length;

  // ** declare and define component helper methods
  const handleStepBack = () => {
    changeAuthStatus(AuthStatusEnum.NotAuth);
  };

  // ** return component ui
  return (
    <Container maxWidth="md">
      <Stack>
        <Card>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Button
              variant="text"
              sx={{ fontWeight: 700, fontSize: 18 }}
              startIcon={<i className="ri-arrow-right-line"></i>}
              onClick={handleStepBack}
            >
              العودة
            </Button>
            {userTenants.length > 3 && (
              <Button
                variant="text"
                sx={{
                  fontWeight: 700,
                  fontSize: 18,
                  textDecoration: "underline",
                }}
                onClick={() => setShoeMore(true)}
                disabled={shoeMore}
              >
                المزيد
              </Button>
            )}
          </Stack>
          <Typography
            variant="body2"
            textAlign={"center"}
            fontSize={22}
            fontWeight={800}
            my={2}
          >
            الشركات المسجلة
          </Typography>
          <Grid container mb={3}>
            {(shoeMore ? userTenants : userTenants?.slice(0, sliceLen))?.map(
              (tenant) => (
                <CompanyCard key={tenant.id} tenant={tenant} />
              )
            )}
          </Grid>
        </Card>
        <Typography variant="body2" color="error"></Typography>
      </Stack>
    </Container>
  );
}
