export enum UserRolesEnum {
  None,
  Admin,
  Employee,
  Client,
  Freelance,
}

export enum UserIdentifiersEnum {
  None,
  NationalIdentity,
  Passport,
  Iqama,
  BorderNumber,
}
