import type { ReactNode } from "react";
import React, { Suspense, useContext, useState } from "react";

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ColumnDef } from "@tanstack/react-table";
import classNames from "classnames";

import { CardContent, Grid, LinearProgress } from "@mui/material";

// Style Imports

import { useMutation } from "@tanstack/react-query";

import LoadingButton from "@mui/lab/LoadingButton";

// import { SetCompanyButton } from "./SetCompanyDialog";

import { SetSchemaButton } from "./SetSchemaDialog";
import { CompaniesContext } from "../../../context/Companies";
import { getExportCompaniesUrl } from "../../../../../utils/api/companies/export-rows";
import { NavLink } from "react-router-dom";
import { fuzzyFilter } from "../../../../../utils/table/fuzzyFilter";
import tableStyles from "../../../../../utils/styles/table.module.css";
//

import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SetCompanyDrawer from "./SetCompanyDialog";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));
// declare props type
type Props<T> = {
  columns: ColumnDef<T, any>[];
  data?: T[];
  searchElement?: ReactNode;
};

export default function RenderCompainesTable<T>({
  columns,
  data,
  searchElement,
}: Props<T>) {
  const table = useReactTable<T>({
    columns,
    data: data || [],
    filterFns: { fuzzy: fuzzyFilter },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: fuzzyFilter,
    getRowId: (row) => (row as any).id, // Ensure each row has a unique 'id' field
    enableRowSelection: true, // Enables row selection
  });

  const {
    mutate,
    data: exportData,
    isPending,
    reset,
  } = useMutation({
    async mutationFn() {
      // Get selected row IDs from the table instance
      const selectedRowIds = table
        .getSelectedRowModel()
        .rows.map((row) => (row.original as any).id);

      // Pass the selected row IDs to the export function
      const res = getExportCompaniesUrl({ ids: selectedRowIds });

      return res;
    },
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { query } = useContext(CompaniesContext);
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            {searchElement}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="flex gap-2 items-center justify-between">
              <>
                <div>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    انشاء شركة
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenDialog(!openDialog);
                      }}
                      disableRipple
                    >
                      شركة جديدة
                    </MenuItem>
                  </StyledMenu>
                </div>
                <SetCompanyDrawer
                  open={openDialog}
                  onClose={() => setOpenDialog(!openDialog)}
                />
                {/* <Menu
                  menuButton={
                    <Button
                      variant="contained"
                      endIcon={<i className="ri-arrow-down-s-fill" />}
                    >
                      انشاء شركة
                    </Button>
                  }
                  transition
                >
                  <SetCompanyButton />
                </Menu> */}
              </>

              {exportData ? (
                <Button
                  component={NavLink}
                  target="_blank"
                  to={exportData.url}
                  color="success"
                  variant="contained"
                  onClick={reset}
                >
                  تحميل الملف
                </Button>
              ) : (
                <LoadingButton
                  endIcon={<i className="ri-upload-2-line" />}
                  color="inherit"
                  onClick={() => mutate()}
                  variant="outlined"
                  loading={isPending}
                >
                  تصدير
                </LoadingButton>
              )}
              {query.data?.schema && (
                <SetSchemaButton
                  onSuccess={() => {
                    query.refetch();
                  }}
                  schema={query.data?.schema.schema}
                  schemaId={query.data?.schema.id}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <div className="overflow-x-auto">
          <table className={tableStyles.table}>
            {/* set table headers */}
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="text-center">
                      {header.isPlaceholder ? null : (
                        <div
                          className={classNames({
                            "flex items-center justify-center":
                              header.column.getIsSorted(),
                            "cursor-pointer select-none":
                              header.column.getCanSort(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getIsSorted() && (
                            <>
                              {header.column.getIsSorted() === "asc" ? (
                                <i className="ri-arrow-up-s-line text-xl" />
                              ) : (
                                <i className="ri-arrow-down-s-line text-xl" />
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* set data body */}
            <tbody>
              <Suspense fallback={<LinearProgress />}>
                {table.getFilteredRowModel().rows.length === 0 ? (
                  <tr>
                    <td
                      colSpan={table.getVisibleFlatColumns().length}
                      className="text-center"
                    >
                      No data available
                    </td>
                  </tr>
                ) : (
                  table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className={classNames({ selected: row.getIsSelected() })}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="text-start">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </Suspense>
            </tbody>
          </table>
        </div>
      </CardContent>
    </>
  );
}
