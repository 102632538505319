import { Button } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LangContext } from "../../context/LangContext";
import deleteCookie from "../../utils/cookies/deleteCookie";
import { AuthContext } from "../../context/AuthContext";

export default function DummyContent() {
  const langContext = useContext(LangContext);
  let { t } = useTranslation();
  const { lang } = langContext;
  const { SignOutMethod } = useContext(AuthContext);

  return (
    <div>
      <h1>Home Page</h1>
      <p>{t("welcome")}</p>
      <Button variant="contained" color="error" onClick={SignOutMethod}>
        Sign Out
      </Button>
      <br />
      <br />
      <Button
        variant="contained"
        onClick={() => langContext.changeLang(lang() === "ar" ? "en" : "ar")}
      >
        Change Language to {lang() === "ar" ? "EN" : "AR"}
      </Button>
    </div>
  );
}
