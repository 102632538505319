import { Stack, Typography } from "@mui/material";

export default function CenterDialogContent({ content }: { content: string }) {
  return (
    <Stack
      width={"300px"}
      height={"50px"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography
        variant="body2"
        textAlign={"center"}
        fontSize={20}
        fontWeight={800}
      >
        {content}
      </Typography>
    </Stack>
  );
}
