import { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ChildrenPropsType } from "../../../types/GlobalTypes";
import { DRAWER_WIDTH } from "../../../constants/layout/drawerWidth";
import NavbarContent from "./NavbarContent";
// Icons
import SettingsIcon from "@mui/icons-material/Settings";

import LeftSideDialog from "../../dialogs/left-side";
import MianSideBar, { DrawerHeader } from "./SideBar";
import GoToTopButton from "../../GoToTopButton";
import WebSiteSettingDialog from "./WebSiteSettingDialog";
import { Typography } from "@mui/material";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

export default function MainLayout(props: ChildrenPropsType) {
  const [open, setOpen] = useState(true);
  const [openSettingDialog, setOpenSettingDialog] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {/* MenuIcon to toggle sidebar */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
              },
              open && { display: "none" },
            ]}
          >
            <MenuIcon />
          </IconButton>
          {/* Navbar content */}
          <NavbarContent />
        </Toolbar>
      </AppBar>
      {/* Drawer - Sidebar */}
      <MianSideBar open={open} setOpen={setOpen} />

      {/* main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 1, position: "relative" }}>
        <DrawerHeader />
        <LeftSideDialog
          open={openSettingDialog}
          setOpen={setOpenSettingDialog}
          title={
            <Typography
              variant="body2"
              textAlign={"center"}
              fontSize={18}
              fontWeight={700}
            >
              أعدادات الموقع
            </Typography>
          }
          dialogContent={<WebSiteSettingDialog />}
        />
        {props.children}
        {/* themes setting */}
        <IconButton
          color="primary"
          size="large"
          sx={{
            position: "fixed",
            bottom: "3%",
            right: "1%",
          }}
          onClick={() => setOpenSettingDialog(true)}
        >
          <SettingsIcon
            className="App-logo"
            sx={{
              fontSize: "2rem",
            }}
          />
        </IconButton>
        {/* GoToTopButton */}
        <GoToTopButton />
      </Box>
    </Box>
  );
}
