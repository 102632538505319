import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/requests/Api";
import { UserType } from "../types/users/UserType";
import axios from "axios";

type ResponseType = {
  count_active_users: number;
  count_inactive_users: number;
  users: UserType[];
  users_count: number;
  users_count_last_month: number;
};

const fetchData = async (params: string) => {
  // prepare helper methods
  const url = params.length > 0 ? `user?${params}` : `user`;
  const Response = await axios.get<ResponseType>(api`${url}`);

  return Response.data;
};

export default function useUsersData(params: string) {
  return useQuery({
    queryKey: [`users-data`, params],
    queryFn: () => fetchData(params),
  });
}
