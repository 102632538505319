// Hooks
import { useTheme } from "@emotion/react";

// MUI
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

// Style Imports
import classNames from "classnames";
import styles from "./styles.module.css";

// Icons
import LightModeIcon from "@mui/icons-material/LightMode";
import ModeNightIcon from "@mui/icons-material/ModeNight";

const ColorItem = (props: ColorItemProps) => {
  const theme = useTheme();

  return (
    <Stack
      width={35}
      height={35}
      alignItems={"center"}
      justifyContent={"center"}
      borderRadius={"8px"}
      border={`1px solid`}
    >
      <Box
        width={24}
        height={24}
        bgcolor={`${props.color}`}
        borderRadius={"5px"}
        border={`1px solid ${props.color}`}
      />
    </Stack>
  );
};

export default function WebSiteSettingDialog() {
  const colors = ["#f72585", "#4361ee", "#ff595e", "#a0d2db", "#06d6a0"];

  return (
    <Stack
      alignItems={"center"}
      height={"100%"}
      justifyContent={"space-between"}
    >
      <Stack spacing={3}>
        {/* main color */}
        <Typography variant="body1" fontSize={18} fontWeight={600}>
          اللون الأساسي
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-around"}
        >
          {colors.map((color) => (
            <ColorItem key={color} color={color} />
          ))}
        </Stack>
        {/* mode */}
        <Typography variant="body1" fontSize={18} fontWeight={600}>
          الوضع
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"start"}
          spacing={5}
        >
          <Stack
            onClick={() => {}}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              width: "25%",
              height: "56px",
              border: "1px solid lightgray",
              borderRadius: "12px",
            }}
          >
            <LightModeIcon />
          </Stack>

          <Stack
            onClick={() => {}}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              width: "25%",
              height: "56px",
              border: "1px solid lightgray",
              borderRadius: "12px",
            }}
          >
            <ModeNightIcon />
          </Stack>
        </Stack>

        {/* language */}
        <Typography variant="body1" fontSize={18} fontWeight={600}>
          اللغة
        </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="ar"
            control={<Radio />}
            label="اللغة العربية (Ar)"
          />
          <FormControlLabel
            value="en"
            control={<Radio />}
            label="English (En)"
          />
        </RadioGroup>
      </Stack>

      <Stack spacing={2} width={'100%'}>
        <Button fullWidth variant="contained">حفظ</Button>
        <Button fullWidth variant="outlined">رجوع</Button>
      </Stack>
    </Stack>
  );
}

type ColorItemProps = {
  color: string;
};
