import axios from "axios";

import type { LaravelPagination } from "./../../../types/api/common/LaravelPagintaion/index";
import { TableSchema, TableSchemaRow } from "../../../types/api/table-schema";
import { Tenant } from "../../../types/api/common/Tenant";
import { api } from "../../requests/Api";

interface Root {
  tenants_preview: LaravelPagination<TableSchemaRow[]>;
  tenants: LaravelPagination<Tenant[]>;
  schema: TableSchema;
  tenants_count: number;
  active_tenants_count: number;
  message: string;
  status: boolean;
}

export const getCompaniesTable = async (params: GetCompaniesTableParams) => {
  const data = (await axios.get<Root>(api`tenant`, { params })).data;

  return data;
};

export type GetCompaniesTableParams = {
  page?: number;
  name?: string;
  limit?: any;
};
