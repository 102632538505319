// MUI
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// Types
import { SideBarListItemType } from "../../../types/LayoutTypes";

// Hooks
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function SideBarListItem(props: PropsType) {
  // ** declare and define component state and variables
  let { open, item } = props;
  const navigator = useNavigate();
  const [openCollapse, setOpenCollapse] = useState(false);
  const hasChildren = Boolean(item?.children);

  // ** declare and define component helper methods
  const handleClick = () => {
    if (hasChildren) {
      setOpenCollapse((prev) => !prev);
    } else {
      navigator(item?.href ?? "/");
    }
  };

  // ** return component ui
  return (
    <ListItem key={item?.id} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={[
          {
            minHeight: 48,
            px: 2.5,
          },
          open
            ? {
                justifyContent: "initial",
              }
            : {
                justifyContent: "center",
              },
        ]}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={[
            {
              minWidth: 0,
              justifyContent: "center",
            },
            open
              ? {
                  mr: 3,
                }
              : {
                  mr: "auto",
                },
          ]}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>{item.text}</span>
              {hasChildren && (
                <>
                  {!openCollapse ? (
                    <KeyboardArrowRightIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </>
              )}
            </Typography>
          }
          sx={[
            open
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0,
                },
          ]}
        />
      </ListItemButton>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasChildren &&
            item?.children?.map((subItem) => (
              <ListItemButton
                key={subItem.id}
                sx={{ pl: 4 }}
                onClick={() => navigator(subItem.href ?? "/")}
              >
                <ListItemIcon>{subItem.icon}</ListItemIcon>
                <ListItemText primary={subItem.text} />
              </ListItemButton>
            ))}
        </List>
      </Collapse>
    </ListItem>
  );
}

type PropsType = {
  open: boolean;
  item: SideBarListItemType;
};
