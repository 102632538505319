// types
import type { ReactNode } from "react";

// Hooks
import { createContext, useState } from "react";
import useUsersData from "../../../hooks/useUsersData";
import useUserLookUpsData from "../../../hooks/useUserLookUpsData";
import useValidationData from "../../../hooks/useValidationData";
import { UserLookUpsType, UserType } from "../../../types/users/UserType";
import { validationType } from "../../../types/GlobalTypes";
import ScreenCenterDialog from "../../../components/dialogs/screen-center-dialog";
import CenterDialogSuccessTtitle from "./helpers-components/CenterDialogSuccessTtitle";
import CenterDialogContent from "./helpers-components/CenterDialogContent";
import CenterDialogErrorTitle from "./helpers-components/CenterDialogErrorTitle";

// import packages

export const UsersContext = createContext<UsersContextType>({
  params: "",
  formMode: "Create",
  editedUser: undefined,
  openFeedBackDialog: false,
  userLookups: undefined,
  validationsRules: undefined,
  handleChangeSearchParams: () => {},
  count_active_users: undefined,
  count_inactive_users: undefined,
  users: undefined,
  users_count: undefined,
  users_count_last_month: undefined,
  usersIsLoading: false,
  refreshUserData: () => {},
  userRegisteredBefore: null,
  handleChangeFormMode: (mode: "Create" | "Edit") => {},
  controlFeedbackDialog: (open: boolean, error?: boolean) => {},
  handleSetUserRegisteredBefore: (user: UserType | null) => {},
  handeSetDialogContent: (content: string) => {},
  handleStoreEditedUser: (user: UserType | undefined) => {},
});

export const UsersContextProvider = ({ children }: { children: ReactNode }) => {
  // ** declare and define component state and variables
  const [params, setParams] = useState("");
  const {
    data: usersResponse,
    isLoading: usersIsLoading,
    refetch: refetchUsers,
  } = useUsersData(params);
  const { data: userLookups } = useUserLookUpsData(); //fetch user lookups
  const { data: validationsRules } = useValidationData(); //fetch validations rules
  const [openFeedBackDialog, setOpenFeedBackDialog] = useState(false); //control open/close feed back dialog
  const [userRegisteredBefore, setUserRegisteredBefore] =
    useState<UserType | null>(null); //durring create if user exist store it here.
  // -- center dialog states
  const [dialogContent, setDialogContent] = useState("");
  const [dialogHaveError, setDialogHaveError] = useState(false);
  // -- Form Mode
  const [formMode, setFormMode] = useState<"Create" | "Edit">("Create");
  const [editedUser, setEditedUser] = useState<UserType | undefined>(undefined);
  // ** handle side effects

  // ** declare and define component helper methods
  const refreshUserData = () => {
    refetchUsers();
  };

  function handleChangeSearchParams(str: string) {
    setParams(str);
  }

  function controlFeedbackDialog(open: boolean, error?: boolean) {
    setOpenFeedBackDialog(open);
    setDialogHaveError(Boolean(error));
  }

  function handleSetUserRegisteredBefore(user: UserType | null) {
    setUserRegisteredBefore(user);
  }

  function handeSetDialogContent(content: string) {
    setDialogContent(content);
  }

  function handleChangeFormMode(mode: "Create" | "Edit") {
    setFormMode(mode);
  }

  function handleStoreEditedUser(user: UserType | undefined) {
    setEditedUser(user);
  }

  // ** return component ui
  return (
    <UsersContext.Provider
      value={{
        params,
        formMode,
        userLookups,
        editedUser,
        usersIsLoading,
        validationsRules,
        refreshUserData,
        openFeedBackDialog,
        controlFeedbackDialog,
        handleChangeSearchParams,
        userRegisteredBefore,
        handeSetDialogContent,
        handleChangeFormMode,
        handleStoreEditedUser,
        handleSetUserRegisteredBefore,
        count_active_users: usersResponse?.count_active_users,
        count_inactive_users: usersResponse?.count_inactive_users,
        users: usersResponse?.users,
        users_count: usersResponse?.users_count,
        users_count_last_month: usersResponse?.users_count_last_month,
      }}
    >
      {children}
      <ScreenCenterDialog
        open={openFeedBackDialog}
        setOpen={setOpenFeedBackDialog}
        title={
          !dialogHaveError ? (
            <CenterDialogSuccessTtitle />
          ) : (
            <CenterDialogErrorTitle />
          )
        }
        dialogContent={<CenterDialogContent content={dialogContent} />}
      />
    </UsersContext.Provider>
  );
};

type UsersContextType = {
  params: string;
  editedUser: UserType | undefined;
  formMode: "Create" | "Edit";
  openFeedBackDialog: boolean;
  userLookups: UserLookUpsType | undefined;
  validationsRules: validationType[] | undefined;
  handleChangeSearchParams(str: string): void;
  count_active_users: number | undefined;
  count_inactive_users: number | undefined;
  users: UserType[] | undefined;
  users_count: number | undefined;
  users_count_last_month: number | undefined;
  usersIsLoading: boolean;
  refreshUserData: () => void;
  userRegisteredBefore: UserType | null;
  handeSetDialogContent(content: string): void;
  handleChangeFormMode(mode: "Create" | "Edit"): void;
  controlFeedbackDialog(open: boolean, error?: boolean): void;
  handleSetUserRegisteredBefore(user: UserType | null): void;
  handleStoreEditedUser(user: UserType | undefined): void;
};
