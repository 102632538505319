// Hooks
import { useContext, useState } from "react";

// MUI
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

// Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Packages
import { z } from "zod";
import axios from "axios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// components
import { api } from "../../../../../utils/requests/Api";
import {
  AuthContext,
  AuthStatusEnum,
} from "../../../../../context/AuthContext";
import { errorMessage } from "../../../../../utils/notifications/errorMessage";
import { successMessage } from "../../../../../utils/notifications/successMessage";
import { useNavigate } from "react-router-dom";
import setCookie from "../../../../../utils/cookies/setCookie";

export default function EnterPasswordScreen() {
  // ** declare and define component state and variables
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });
  const navigator = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { identifier, globalId, selectedTenant, changeAuthStatus } =
    useContext(AuthContext);

  // ** declare and define component helper methods
  const onSubmit = (data: FormData) => {
    try {
      // sign in
      const url = api`login-with-different-methods`;

      const body = {
        password: data.password,
        global_id: globalId,
      };

      const headers = {
        "Content-Type": "application/json",
        "X-Tenant": selectedTenant?.id,
      };

      //send request.
      axios
        .post<{ token: string }>(url, body, { headers })
        .then((response) => {
          if (response.data?.token) {
            // set token in axios headers
            axios.defaults.headers.common.Authorization = `Bearer ${response.data?.token}`;
            // feedback
            successMessage(
              "تم تسجيل الدخول بنجاح ستم تحويلك الى الصفحة المستخدمسن"
            );
            setCookie("token", response.data?.token, 2);
            navigator("/users");
            setTimeout(() => {
              changeAuthStatus(AuthStatusEnum.Authed);
            }, 1000);
          } else {
            errorMessage("خطا غير متوقع برجاء المحاولة مرة اخري");
          }
        })
        .catch((err) => {
          if (err.status === 401) errorMessage("كلمة المرور غير صحيحة");
          else errorMessage("خطا غير متوقع برجاء المحاولة فى وقت اخر");
        });
    } catch (error) {
      errorMessage("خطا غير متوقع برجاء المحاولة فى وقت اخر");
    }
  };

  function handleForgetPassword() {
    // prepare body
    const body = { identifier };
    // send request
    axios
      .post(api`identifier-check?forget_password=1`, body)
      .then(() => {
        changeAuthStatus(AuthStatusEnum.ForgetPassword);
      })
      .catch(() => {
        errorMessage("حدث خطا غير متوقع الرجاء المحاولة فى وقت لاحق");
      });
  }

  // ** component ui
  return (
    <Card className="flex flex-col" sx={{ width: "340px" }}>
      {/* card header */}
      <CardHeader
        title={
          <Typography
            variant="body2"
            fontWeight={600}
            fontSize={26}
            textAlign={"center"}
          >
            ادخل كلمة المرور
          </Typography>
        }
      ></CardHeader>
      {/* card content */}
      <CardContent className="p-6 sm:!p-12">
        <div className="flex flex-col gap-5">
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <TextField
              autoFocus
              fullWidth
              type={showPassword ? "text" : "password"}
              {...register("password")}
              label="أدخل كلمة المرور"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
            />

            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={!isValid}
            >
              {false ? "جاري التنفيذ.." : "دخول"}
            </Button>
          </form>
          <Typography
            variant="body2"
            fontWeight={400}
            fontSize={18}
            sx={{ cursor: "pointer" }}
            onClick={handleForgetPassword}
          >
            هل نسيت كلمة المرور؟{" "}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

// Define schema with Zod
const schema = z.object({
  password: z.string().min(1, { message: "كلمة المرور مطلوبة" }),
});

type FormData = z.infer<typeof schema>;
