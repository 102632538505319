import { Routes, Route } from "react-router-dom";
import TestPage from "./pages/TestPage";
import HomePage from "./pages/home";
import MainLayout from "./components/Layout/main";
import Companies from "./pages/companies";
import SignInPage from "./pages/auth/signin";
import AuthGuard from "./guards/AuthGuard";
import NotAuthGuard from "./guards/NotAuthGuard";
import UsersPage from "./pages/users";

const AuthedRoutes = () => {
  return (
    <AuthGuard>
      <MainLayout>
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="test" element={<TestPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="companies" element={<Companies />} />
      </Routes>
      </MainLayout>
    </AuthGuard>
  );
};

const NotAuthedRoutes = () => {
  return (
    <NotAuthGuard>
      <Routes>
        <Route path="signin" element={<SignInPage />} />
      </Routes>
    </NotAuthGuard>
  );
};


function RoutesComponent() {
  return (
    <>
      <Routes>
        <Route path="/*" element={<AuthedRoutes />} />
        <Route path="/auth/*" element={<NotAuthedRoutes />} />
        <Route path="*" element={<>No Found | 404</>} />
      </Routes>
    </>
  );
}

export default RoutesComponent;
