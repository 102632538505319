import { encryptDecryptNumber } from "../encryption/encryptDecryptNumber";
import getCookie from "./getCookie";

function getGlobalIdCookie(): string {
  const encryptedVal = getCookie("global-id") as string;
  const decryptedVal = encryptDecryptNumber(Number(encryptedVal));
  
  return decryptedVal.toString();
}

export default getGlobalIdCookie;
