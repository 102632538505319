// import packages
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// MUI
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";

// Hooks
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";

// Components
import { UsersContext } from "../../context/UsersContext";

import { userIdentifiersWays } from "../../../../constants/users/UserIdentifiersWays";
import axios from "axios";
import { api } from "../../../../utils/requests/Api";
import { UserIdentifiersEnum } from "../../../../types/users/UserRoles";
import { UserType } from "../../../../types/users/UserType";
import { successMessage } from "../../../../utils/notifications/successMessage";
import { errorMessage } from "../../../../utils/notificationsMessages";

export default function SetUserDialog(props: PropsType) {
  // ** declare and define component state and variable
  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });
  const { open, setOpen } = props;
  const { userLookups, formMode, editedUser } = useContext(UsersContext);
  const [tenantCountryId, setTenantCountryId] = useState("");
  const [selectedIdentifierType, setSelectedIdentifierType] =
    useState<UserIdentifiersEnum>(UserIdentifiersEnum.None);
  const [identifierLabel, setIdentifierLabel] = useState("الهوية الوطنية");
  const {
    refreshUserData,
    userRegisteredBefore,
    handeSetDialogContent,
    controlFeedbackDialog,
    handleSetUserRegisteredBefore,
  } = useContext(UsersContext);
  // ** control fields visibility of fields
  const [visibleFields, setVisibleFields] = useState({
    user_type_id: true,
    name: false,
    country_id: false,
    identifier_type: false,
    identifier: false,
    email: false,
    phone: false,
    phone_code: false,
  });

  // Watch specific fields to determine if the next field should become visible
  const userTypeId = watch("user_type_id");
  const tenant_id = watch("tenant_id");
  const name = watch("name");
  const countryId = watch("country_id");
  const identifierType = watch("identifier_type");
  const identifier = watch("identifier");
  const email = watch("email");
  const phone = watch("phone");

  // ** handle side effects
  useEffect(() => {
    if (formMode === "Create") {
      reset({
        name: "",
        user_type_id: "",
        phone: "",
      });
      setVisibleFields({
        user_type_id: true,
        name: false,
        country_id: false,
        identifier_type: false,
        identifier: false,
        email: false,
        phone: false,
        phone_code: false,
      });
    } else {
      // edit - set edited user data
      console.log("editedUserrrrr", editedUser);
      // get edited user identifier and identifer type
      let _identifier = "",
        _identifierType: UserIdentifiersEnum = UserIdentifiersEnum.None;
      if (editedUser?.identity) {
        _identifier = editedUser?.identity;
        _identifierType = UserIdentifiersEnum.NationalIdentity;
        setIdentifierLabel("رقم الهوية الوطنية");
        setSelectedIdentifierType(UserIdentifiersEnum.NationalIdentity);
      } else if (editedUser?.border_number) {
        _identifier = editedUser?.border_number;
        _identifierType = UserIdentifiersEnum.BorderNumber;
        setIdentifierLabel("رقم حدود");
        setSelectedIdentifierType(UserIdentifiersEnum.BorderNumber);
      } else if (editedUser?.iqama) {
        _identifier = editedUser?.iqama;
        _identifierType = UserIdentifiersEnum.Iqama;
        setIdentifierLabel("رقم الاقامة");
        setSelectedIdentifierType(UserIdentifiersEnum.Iqama);
      } else if (editedUser?.passport) {
        _identifier = editedUser?.passport;
        _identifierType = UserIdentifiersEnum.Passport;
        setIdentifierLabel("رقم جواز سفر");
        setSelectedIdentifierType(UserIdentifiersEnum.Passport);
      }

      reset({
        name: editedUser?.name,
        user_type_id: editedUser?.user_type_id.toString(),
        phone: editedUser?.phone,
        phone_code: editedUser?.phone_code,
        country_id: editedUser?.country_id.toString(),
        email: editedUser?.email,
        identifier: _identifier,
        identifier_type: _identifierType.toString(),
        // tenant_id:editedUser?.t
      });
      setVisibleFields({
        user_type_id: true,
        name: true,
        country_id: true,
        identifier_type: true,
        identifier: true,
        email: true,
        phone: true,
        phone_code: true,
      });
    }
  }, [open]);

  useEffect(() => {
    if (Boolean(userRegisteredBefore)) {
      switch (selectedIdentifierType) {
        case UserIdentifiersEnum.NationalIdentity:
          if (userRegisteredBefore?.identity) {
            setValue("identifier", userRegisteredBefore?.identity);
          } else {
            setValue("identifier", "");
          }
          break;
        case UserIdentifiersEnum.Iqama:
          if (userRegisteredBefore?.iqama) {
            setValue("identifier", userRegisteredBefore?.iqama);
          } else {
            setValue("identifier", "");
          }
          break;
        case UserIdentifiersEnum.BorderNumber:
          if (userRegisteredBefore?.border_number) {
            setValue("identifier", userRegisteredBefore?.border_number);
          } else {
            setValue("identifier", "");
          }
          break;
        case UserIdentifiersEnum.Passport:
          if (userRegisteredBefore?.passport) {
            setValue("identifier", userRegisteredBefore?.passport);
          } else {
            setValue("identifier", "");
          }
          break;
      }
    }
  }, [userRegisteredBefore, selectedIdentifierType]);

  useEffect(() => {
    let showTenant = false;
    //Employee
    if (userTypeId === "2") {
      showTenant = true;
    }

    setVisibleFields((prev) => ({
      ...prev,
      name: showTenant ? !!tenant_id : !!userTypeId,
      country_id: !!name,
      identifier_type: !!countryId,
      identifier: !!identifierType,
      email: !!identifier,
      phone: !!email,
      phone_code: !!phone,
    }));
  }, [
    userTypeId,
    name,
    tenant_id,
    countryId,
    identifierType,
    identifier,
    email,
    phone,
  ]);

  useEffect(() => {
    let _contryId =
      userLookups?.tenants
        ?.find((com) => com.id + "" === tenant_id)
        ?.country_id?.toString() ?? "";

    setTenantCountryId(_contryId);
  }, [tenant_id]);

  useEffect(() => {
    if (countryId === tenantCountryId) {
      // national id
      if (selectedIdentifierType !== UserIdentifiersEnum.NationalIdentity) {
        setIdentifierLabel("الهوية الوطنية");
        setSelectedIdentifierType(UserIdentifiersEnum.NationalIdentity);
        setValue(
          "identifier_type",
          UserIdentifiersEnum.NationalIdentity.toString()
        );
      }
    } else {
      if (selectedIdentifierType === UserIdentifiersEnum.NationalIdentity) {
        setIdentifierLabel("رقم الجواز");
        setSelectedIdentifierType(UserIdentifiersEnum.Passport);
        setValue("identifier_type", UserIdentifiersEnum.Passport.toString());
      }
    }
  }, [tenantCountryId, countryId]);

  // ** declare and define component helper methods
  const onSubmit = (data: FormData) => {
    // Prepare Form Body
    const formBody = {
      name: data.name ?? null,
      email: data.email ?? null,
      user_type_id: data.user_type_id ?? null,
      phone: data.phone ?? null,
      tenant_id: data.tenant_id ?? null,
      country_id: Boolean(tenantCountryId) ? tenantCountryId : "1",
      phone_code: data.phone_code ?? null,
      passport:
        UserIdentifiersEnum.Passport === selectedIdentifierType
          ? data.identifier ?? null
          : null,
      identity:
        UserIdentifiersEnum.NationalIdentity === selectedIdentifierType
          ? data.identifier ?? null
          : null,
      iqama:
        UserIdentifiersEnum.Iqama === selectedIdentifierType
          ? data.identifier ?? null
          : null,
      border_number:
        UserIdentifiersEnum.BorderNumber === selectedIdentifierType
          ? data.identifier ?? null
          : null,
    };

    //send request
    axios
      .post<{ user: UserType }>(api`register`, formBody)
      .then((response) => {
        successMessage("تم أضافة المستخدم بنجاح");
        refreshUserData();
        handeSetDialogContent(response?.data?.user?.id.toString());
        controlFeedbackDialog(true);
        setOpen(false);
      })
      .catch((err) => {
        errorMessage("تعذر الحذف");
        handeSetDialogContent(err?.response?.data?.message);
        controlFeedbackDialog(true, true);
      });
  };

  // handle get user data after blur if user exist
  async function handleUserOnBlur(name: string) {
    try {
      const response = await axios.post<ResType>(api`user-by-data`, { name });
      handleSetUserRegisteredBefore(response.data.user);
      return response.data.exist ? false : true;
    } catch (error) {
      return false;
    }
  }

  // ** component ui
  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-5"
    >
      {/* user type */}
      {visibleFields.user_type_id && (
        <FormControl fullWidth>
          <InputLabel>{"نوع المستخدم"}</InputLabel>
          <Select
            label={"نوع المستخدم"}
            size="small"
            fullWidth={true}
            {...register("user_type_id")}
          >
            {userLookups?.user_types?.map((user) => (
              <MenuItem key={user.id} value={user.id + ""}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* company */}
      {userTypeId === "2" && (
        <FormControl fullWidth>
          <InputLabel>{"اسم الشركة"}</InputLabel>
          <Select
            label={"اسم الشركة"}
            size="small"
            fullWidth={true}
            {...register("tenant_id")}
          >
            {userLookups?.tenants?.map((item) => (
              <MenuItem key={item.id} value={item.id + ""}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* user name */}
      {visibleFields.name && (
        <TextField
          fullWidth
          {...register("name")}
          label="أسم المستخدم"
          size="small"
          onBlur={(e) => {
            handleUserOnBlur(e.target.value);
          }}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ""}
        />
      )}

      {/* user country */}
      {visibleFields.country_id && (
        <FormControl fullWidth>
          <InputLabel>{"الجنسية"}</InputLabel>
          <Select
            label={"الجنسية"}
            size="small"
            fullWidth={true}
            {...register("country_id")}
          >
            {userLookups?.countries?.map((item) => (
              <MenuItem key={item.id} value={item.id + ""}>
                {item.name_ar}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* identifier type */}
      {visibleFields.identifier_type && (
        <FormControl component="fieldset" error={!!errors.identifier_type}>
          <Controller
            name="identifier_type"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <RadioGroup {...field} row>
                {userIdentifiersWays?.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    value={item.value}
                    disabled={
                      item.value !== UserIdentifiersEnum.NationalIdentity
                        ? tenantCountryId === countryId
                        : tenantCountryId !== countryId
                    }
                    onChange={(_, checked) => {
                      if (checked) {
                        setSelectedIdentifierType(item.value);
                        setIdentifierLabel(item.identifierLabel);
                      }
                    }}
                    checked={item.value === selectedIdentifierType}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      )}

      {/* user identifier */}
      {visibleFields.identifier && (
        <TextField
          fullWidth
          {...register("identifier")}
          label={identifierLabel}
          size="small"
          error={!!errors.identifier}
          helperText={errors.identifier ? errors.identifier.message : ""}
        />
      )}

      {/* user email */}
      {visibleFields.email && (
        <TextField
          fullWidth
          {...register("email")}
          label="البريد الالكتروني"
          size="small"
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ""}
        />
      )}

      {/* user phone */}
      {visibleFields.phone && (
        <div className="flex items-start justify-between gap-2">
          <TextField
            fullWidth
            {...register("phone")}
            label="رقم الجوال"
            size="small"
            error={!!errors.phone}
            helperText={errors.phone ? errors.phone.message : ""}
          />
          <Select size="small" {...register("phone_code")} defaultValue={"20"}>
            {userLookups?.countries?.map((item) => (
              <MenuItem key={item.id} value={item.phonecode + ""}>
                <Stack
                  height={"100%"}
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    src={item?.flag_url ?? ""}
                    width={"40px"}
                    height={"40px"}
                    alt={item.shortname}
                    style={{ marginTop: "0.5rem" }}
                  />
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      <Button fullWidth variant="contained" type="submit" disabled={!isValid}>
        {isSubmitting ? "جاري التنفيذ.." : "دخول"}
      </Button>
    </form>
  );
}

type ResType = {
  exist: boolean;
  user: UserType | null;
};

const userNameInCompany = async (name: string) => {
  try {
    const response = await axios.post<ResType>(api`user-by-data`, { name });
    return response.data.exist ? false : true;
  } catch (error) {
    return false;
  }
};

const emailInCompany = async (email: string) => {
  try {
    const response = await axios.post<ResType>(api`user-by-data`, { email });
    return response.data.exist ? false : true;
  } catch (error) {
    return false;
  }
};

// Define schema with Zod
const schema = z.object({
  user_type_id: z.string(),
  tenant_id: z.string().optional(),
  name: z
    .string()
    .min(1, { message: "أسم المستخدم مطلوب" })
    .regex(/^[\u0600-\u06FFa-zA-Z\s]*$/, {
      message: "لا بد لأسم المستخدم ان يحتوي علي حروف فقط",
    })
    .regex(
      /^[\u0600-\u06FFa-zA-Z]+\s[\u0600-\u06FFa-zA-Z]+\s[\u0600-\u06FFa-zA-Z]+$/,
      {
        message: "لا بد ان يتكون أسم المستخدم من 3 أجزاء مثل:أحمد محمد علي",
      }
    )
    .refine(async (name) => await userNameInCompany(name), {
      message: "أسم المستخدم مسجل فى الشركة بالفعل",
    }),
  country_id: z.string(),
  identifier_type: z.string(),
  identifier: z.string().min(1, { message: "تحقيق الشخصية مطلوب" }),
  email: z
    .string()
    .email({ message: "الايميل غير صالح" })
    .refine(async (email) => await emailInCompany(email), {
      message: "أسم المستخدم مسجل فى الشركة بالفعل",
    }),
  phone: z.string().min(1, { message: "أسم المستخدم مطلوب" }),
  phone_code: z.string(),
});

type FormData = z.infer<typeof schema>;

type PropsType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
