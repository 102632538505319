import type { SetStateAction } from "react";

import { useContext, useState } from "react";
// MUI
import { Typography } from "@mui/material";
import { UserType } from "../../../../../types/users/UserType";
import GlobelDropDownMenu, {
  GenericMenuButton,
} from "../../../../../components/drop-down-menu/GlobelDropDownMenu";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { api } from "../../../../../utils/requests/Api";
import { successMessage } from "../../../../../utils/notifications/successMessage";
import { errorMessage } from "../../../../../utils/notificationsMessages";
import ScreenCenterDialog from "../../../../../components/dialogs/screen-center-dialog";
import DeleteUserDialogContent from "./DeleteDialogContent";
import { UsersContext } from "../../../context/UsersContext";

export default function ActionButton(props: PropsType) {
  // ** declare and define component state and variables
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { row, OnSuccessDeleteDialogAction, setOpenEditDialog } = props;
  const { handleChangeFormMode, handleStoreEditedUser } =
    useContext(UsersContext);

  const buttons: GenericMenuButton[] = [
    {
      id: `menu-btn-1`,
      title: (
        <div className="flex items-center gap-4 text-info">
          <RemoveRedEyeIcon />
          <p className="text-slate-300 text-lg">عرض</p>
        </div>
      ),
      disabled: true,
    },
    {
      id: `menu-btn-2`,
      title: (
        <div className="flex items-center gap-4 text-warning">
          <EditIcon />
          <p className="text-slate-300 text-lg">استكمال بيانات</p>
        </div>
      ),
      disabled: true,
    },
    {
      id: `menu-btn-3`,
      title: (
        <div className="flex items-center gap-4 text-warning">
          <EditIcon color="warning"/>
          <p className="text-slate-300 text-lg">تعديل</p>
        </div>
      ),
      onClick: () => {
        handleEditUser();
      },
    },
    {
      id: `menu-btn-4`,
      title: (
        <div className="flex items-center gap-4 text-error">
          <DeleteIcon  color="error"/>
          <p className="text-slate-300 text-lg">حذف</p>
        </div>
      ),
      onClick: () => {
        if (row.tenants?.length <= 1) handleDeleteUser();
        else setOpenDeleteDialog(true);
      },
    },
  ];

  // ** declare and define actions
  const handleDeleteUser = () => {
    const body =
      row?.tenants?.length > 0 ? { tenant_id: [row?.tenants?.[0]?.id] } : {};

    axios
      .post(api`user/delete/${row.id}`, body)
      .then(() => {
        OnSuccessDeleteDialogAction();
        successMessage("تم حذف المستخدم بنجاح");
      })
      .catch(() => {
        errorMessage("تعذر الحذف");
      });
  };

  const handleEditUser = () => {
    handleStoreEditedUser(row);
    handleChangeFormMode("Edit");
    setOpenEditDialog(true);
  };

  // ** return component ui
  return (
    <>
      <GlobelDropDownMenu
        btnTitle="أجراء"
        buttons={buttons}

        //btnColor='inherit'
      />
      <ScreenCenterDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title={
          <Typography
            variant="body1"
            fontWeight={600}
            fontSize={19}
            mt={6}
            mb={2}
          >
            المستخدم الذي ترغب فى حذفه مشترك فى أكثر من شركة
          </Typography>
        }
        dialogContent={
          <DeleteUserDialogContent
            user={row}
            OnSuccessDeleteDialogAction={OnSuccessDeleteDialogAction}
          />
        }
      />
    </>
  );
}

type PropsType = {
  row: UserType;
  OnSuccessDeleteDialogAction: () => void;
  setOpenEditDialog: React.Dispatch<SetStateAction<boolean>>;
};
