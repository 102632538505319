// Hooks
import { useContext, useState } from "react";

// MUI
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { UsersContext } from "../../context/UsersContext";
import { BodyType } from "../../../../types/GlobalTypes";

export default function UsersSearchFilters() {
  // ** handle declare and handleChangeSearchParamsdefine component state and variables
  const [search, setSearch] = useState<BodyType>({});
  const { handleChangeSearchParams, userLookups } = useContext(UsersContext);

  // ** handle declare and define component helper methods
  const handleChange = (key: string, value: string) => {
    setSearch((prev) => ({ ...prev, [key]: value }));
  };

  const handleSearch = () => {
    let params = "";

    //prepare serach
    for (const key in search) {
      if (search.hasOwnProperty(key)) {
        if (search[key] === "none") delete search[key];
        else {
          if (params.length) params += "&";

          params += `${key}=${search[key]}`;
        }
      }
    }

    handleChangeSearchParams(params);
  };

  const handleReset = () => {
    setSearch({
      tenant_id: "none",
      user_type_id: "none",
    });
    handleChangeSearchParams("");
  };

  // ** return component ui
  return (
    <Stack spacing={3} p={4}>
      <Typography variant="body2" fontSize={22} fontWeight={500}>
        فلتر البحث
      </Typography>
      <Stack
        className="gap-4"
        direction={{
          xs: "column",
          sm: "row",
        }}
      >
        {/* user type */}
        <FormControl fullWidth>
          <InputLabel>{"الشركة"}</InputLabel>
          <Select
            label={"الشركة"}
            size="small"
            fullWidth={true}
            value={search["tenant_id"]}
            onChange={(e) =>
              handleChange("tenant_id", e.target.value as string)
            }
          >
            <MenuItem value={"none"}>الكل</MenuItem>
            {userLookups?.tenants?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* user type */}
        <FormControl fullWidth>
          <InputLabel>{"نوع المستخدم"}</InputLabel>
          <Select
            label={"نوع المستخدم"}
            size="small"
            fullWidth={true}
            value={search["user_type_id"]}
            onChange={(e) =>
              handleChange("user_type_id", e.target.value as string)
            }
          >
            <MenuItem value={"none"}>الكل</MenuItem>
            {userLookups?.user_types?.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* user type */}
        <FormControl fullWidth>
          <InputLabel>{"حالة الموظف"}</InputLabel>
          <Select label={"حالة الموظف"} size="small" fullWidth={true}></Select>
        </FormControl>
        {/* action buttons */}
        <Button variant="contained" color="primary" onClick={handleSearch}>
          بحث
        </Button>
        <Button variant="outlined" color="primary" onClick={handleReset}>
          reset
        </Button>
      </Stack>
    </Stack>
  );
}
