import EntryCompanies from "../../views/companies";

function Companies() {
  return (
    <div>
      <EntryCompanies />
    </div>
  );
}

export default Companies;
