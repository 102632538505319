// Hooks
import React, { Suspense, useState } from "react";

// import packages
import * as XLSX from "xlsx";

// MUI
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// tanstack/react-table
import {
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import type { ColumnDef, FilterFn } from "@tanstack/react-table";

// Import styles
import classNames from "classnames";
import tableStyles from "./index.module.css";

// Icons
import TocIcon from "@mui/icons-material/Toc";
import ArticleIcon from "@mui/icons-material/Article";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Components
import LeftSideDialog from "../../dialogs/left-side";

// Define a fuzzy filter function
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({ itemRank });

  return itemRank.passed;
};

// declare props type
type TableBuilderProps<T> = {
  data: T[];
  columns: ColumnDef<T, any>[];

  // -- search feature --//
  globalFilterPlaceholder?: string;
  // -- export data --//
  exportButtonLabel?: string;
  // -- add new item props --//
  openAddDialog: boolean;
  addDialogTitile?: string;
  addButtonLabel?: React.ReactNode;
  addDialogContent?: React.ReactNode;
  durringFireAddFun?: () => void;
  setOpenAddDialog: React.Dispatch<React.SetStateAction<boolean>>;
  // -- control show or hide features -- //
  disableHeader?: boolean; // to control hide or show table header
  disableSearch?: boolean; // to control hide or show table search
  disableExport?: boolean; // to control hide or show export table data
  disabledAddFeature?: boolean; // to control hide or show add new item of type T
};

export default function TableBuilder<T>(props: TableBuilderProps<T>) {
  // ** extract data from props
  const {
    data,
    columns,
    globalFilterPlaceholder = "Search...",
    addButtonLabel,
    addDialogContent = <>Add Dialog</>,
    exportButtonLabel = "Export",
    durringFireAddFun,
    disableHeader = false,
    disableSearch = false,
    disableExport = false,
    disabledAddFeature = false,
    openAddDialog,
    setOpenAddDialog,
    addDialogTitile,
  } = props;

  // ** declare and define component state and variables
  const [
    openColumnVisibilityControlDialog,
    setOpenColumnVisibilityControlDialog,
  ] = useState(false);
  const [pageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnsVisibilityFilters, setColumnsVisibilityFilters] = useState("");

  // ** declare the table instance
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      rowSelection,
      globalFilter,
      columnVisibility,
      pagination: { pageIndex, pageSize },
    },
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // ** declare and define component helper methods
  function exportToExcel() {
    // prepare file name
    const fileName = `${new Date().toLocaleString()}_${Math.random()}`;

    // start generate excel file
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  // ** return ui
  return (
    <Card>
      {/* table header */}
      {disableHeader === false && (
        <CardHeader
          title={
            <Stack
              width={"100%"}
              direction={{
                xs: "column",
                sm: "row",
              }}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {/* part-1 Search */}
              <Stack
                width={{
                  xs: "100%",
                  sm: "60%",
                }}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={3}
              >
                {disableSearch === false && (
                  <TextField
                    variant="outlined"
                    value={globalFilter ?? ""}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder={globalFilterPlaceholder}
                    size="small"
                    sx={{ width: "80%" }}
                  />
                )}
              </Stack>
              {/* part-2 visiablility control & export */}
              <Stack
                width={{
                  xs: "100%",
                  sm: "25%",
                }}
                direction={"row"}
                alignItems={"center"}
                justifyContent={{
                  sx: "space-between",
                  sm: "end",
                }}
                spacing={5}
              >
                {/* add new item */}
                {disabledAddFeature === false && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (durringFireAddFun) durringFireAddFun();
                      setOpenAddDialog(true);
                    }}
                  >
                    {addButtonLabel}
                  </Button>
                )}
                {/* export table data */}
                {disableExport === false && (
                  <Button
                    variant="outlined"
                    startIcon={<ArticleIcon />}
                    onClick={exportToExcel}
                  >
                    {exportButtonLabel}
                  </Button>
                )}
                {/* visiablility control */}
                <IconButton
                  size="large"
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenColumnVisibilityControlDialog(true);
                  }}
                >
                  <TocIcon />
                </IconButton>
              </Stack>
            </Stack>
          }
        />
      )}

      {/* control visibility of table columns */}
      <LeftSideDialog
        open={openColumnVisibilityControlDialog}
        setOpen={setOpenColumnVisibilityControlDialog}
        title={
          <Typography variant="body1" fontSize={"1.2rem"} color={"#fff"}>
            التنقية
          </Typography>
        }
        dialogContent={
          <Stack spacing={1}>
            <TextField
              fullWidth
              id="ColumnVisibilityFilter"
              label=""
              size="small"
              placeholder="بحث"
              onChange={(e) => setColumnsVisibilityFilters(e.target.value)}
            />
            {table
              .getAllColumns()
              .filter((column) => {
                if (typeof column.columnDef.header === "function")
                  return column.id.includes(columnsVisibilityFilters);
                else
                  return column.columnDef.header?.includes(
                    columnsVisibilityFilters
                  );
              })
              .map(
                (column) =>
                  column.getCanHide() && (
                    <FormControlLabel
                      key={column.id}
                      control={
                        <Checkbox
                          checked={column.getIsVisible()}
                          onChange={column.getToggleVisibilityHandler()}
                        />
                      }
                      label={
                        typeof column.columnDef.header === "function"
                          ? column.id
                          : column.columnDef.header
                      }
                    />
                  )
              )}
          </Stack>
        }
      />

      {/* data table */}
      <div className="overflow-x-auto">
        <table className={tableStyles.table}>
          {/* set table headers */}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="text-center">
                    {header.isPlaceholder ? null : (
                      <div
                        className={classNames({
                          "flex items-center justify-center text-center":
                            header.column.getIsSorted(),
                          "cursor-pointer select-none text-center":
                            header.column.getCanSort(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() && (
                          <>
                            {header.column.getIsSorted() === "asc" ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* set data body */}
          <tbody>
            <Suspense fallback={<>Loading...</>}>
              {table.getFilteredRowModel().rows.length === 0 ? (
                <tr>
                  <td
                    colSpan={table.getVisibleFlatColumns().length}
                    className="text-center"
                  >
                    No data available
                  </td>
                </tr>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className={classNames({ selected: row.getIsSelected() })}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="text-center">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </Suspense>
          </tbody>
        </table>
      </div>
      {/* Pagination Controls */}
      <Stack my={2} alignItems="center" justifyContent="center">
        <Pagination
          count={Math.ceil(table.getPageCount())}
          page={table.getState().pagination.pageIndex + 1} // MUI Pagination is 1-based, TanStack is 0-based
          onChange={(_, page) => setPageIndex(page - 1)} // Convert MUI to TanStack
          color="primary"
        />
      </Stack>
      {/* Add Dialog */}
      <LeftSideDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        title={addDialogTitile ?? addButtonLabel}
        dialogContent={addDialogContent}
      />
    </Card>
  );
}
