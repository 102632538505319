import { createContext, useState } from "react";
import { ChildrenPropsType } from "../types/GlobalTypes";
import { TenantType } from "../types/companies/companies";
import setCookie from "../utils/cookies/setCookie";
import { encryptDecryptNumber } from "../utils/encryption/encryptDecryptNumber";
import deleteCookie from "../utils/cookies/deleteCookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// declare and define helper variables and types
export enum AuthStatusEnum {
  NotAuth,
  ChooseTenant,
  Password,
  ForgetPassword,
  ResetPassword,
  EnterOTP,
  Authed,
}

// TODO::create langContext
export const AuthContext = createContext<AuthContextType>({
  globalId: "",
  identifier: "",
  userTenants: [],
  SignOutMethod: () => {},
  selectedTenant: undefined,
  setPasswordFirstTime: false,
  authStatus: AuthStatusEnum.NotAuth,
  handleStoreGlobalId: (id: string) => {},
  handleSetIdentifier: (str: string) => {},
  handleSetPasswordFirstTime: (val: boolean) => {},
  changeAuthStatus: (state: AuthStatusEnum) => {},
  handleStoreTenants: (tenants: TenantType[]) => {},
  handleStoreSelectedTenant: (tenant: TenantType | undefined) => {},
});

// TODO::declare context provider
function AuthContextProvider({ children }: ChildrenPropsType) {
  // * declare and define state and variables
  const navigator = useNavigate();
  const [globalId, setGlobalId] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [userTenants, setUserTenants] = useState<TenantType[]>([]);
  const [setPasswordFirstTime, setSetPasswordFirstTime] = useState(false);
  const [authStatus, setAuthStatus] = useState<AuthStatusEnum>(
    AuthStatusEnum.NotAuth
  );
  const [selectedTenant, setSelectedTenant] = useState<
    TenantType | undefined
  >();

  // * handle direction of page according current language

  // * declare and define helpers methods
  function changeAuthStatus(state: AuthStatusEnum) {
    setAuthStatus(state);
  }
  function handleSetIdentifier(str: string) {
    setIdentifier(str);
  }
  function handleStoreGlobalId(id: string) {
    let encryptedNum = encryptDecryptNumber(id ? +id : -1);
    setCookie("global-id", encryptedNum + "", 5);
    setGlobalId(id);
  }
  function handleStoreTenants(tenants: TenantType[]) {
    setUserTenants(tenants);
  }
  function handleStoreSelectedTenant(tenant: TenantType | undefined) {
    let encryptedNum = encryptDecryptNumber(tenant?.id ?? -1);
    setCookie("x-tenant", encryptedNum + "", 5);
    // set tenant id in axios headers
    axios.defaults.headers.common["X-Tenant"] = tenant?.id;

    setSelectedTenant(tenant);
  }
  function handleSetPasswordFirstTime(val: boolean) {
    setSetPasswordFirstTime(val);
  }
  function SignOutMethod() {
    deleteCookie("x-tenant");
    deleteCookie("token");
    deleteCookie("global-id");
    navigator("/auth/signin");
  }

  // * return component ui
  return (
    <AuthContext.Provider
      value={{
        globalId,
        identifier,
        authStatus,
        userTenants,
        SignOutMethod,
        selectedTenant,
        changeAuthStatus,
        handleStoreTenants,
        handleStoreGlobalId,
        handleSetIdentifier,
        setPasswordFirstTime,
        handleStoreSelectedTenant,
        handleSetPasswordFirstTime,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;

// TODO::declare and define types
type AuthContextType = {
  globalId: string;
  identifier: string;
  SignOutMethod(): void;
  userTenants: TenantType[];
  authStatus: AuthStatusEnum;
  setPasswordFirstTime: boolean;
  selectedTenant: TenantType | undefined;
  handleStoreGlobalId(id: string): void;
  handleSetIdentifier(str: string): void;
  handleSetPasswordFirstTime(val: boolean): void;
  changeAuthStatus(state: AuthStatusEnum): void;
  handleStoreTenants(tenants: TenantType[]): void;
  handleStoreSelectedTenant(tenant: TenantType | undefined): void;
};
