import { Avatar, Stack, Typography } from "@mui/material";

export default function NavbarContent() {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"100%"}
    >
      {/* Company name and logo */}
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Avatar>C</Avatar>
        <Typography variant="h6" noWrap component="div">
          Company Name
        </Typography>
      </Stack>
      {/* Breadcrumb */}
      <>Breadcrumb</>
    </Stack>
  );
}
