import { encryptDecryptNumber } from "../encryption/encryptDecryptNumber";
import getCookie from "./getCookie";

function getXtenantCookie(): string {
  const encryptedVal = getCookie("x-tenant") as string;
  const decryptedVal = encryptDecryptNumber(Number(encryptedVal));
  
  return decryptedVal.toString();
}

export default getXtenantCookie;
