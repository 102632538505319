// MUI
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// Media
import yahooImg from "../../../../../assets/images/logos/yahoo-logo.png";
import nfazImg from "../../../../../assets/images/logos/nfaz.png";
import gmailImg from "../../../../../assets/images/logos/Gmail-Logo.png";

// Packages
import { z } from "zod";
import axios from "axios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext } from "react";
import {
  AuthContext,
  AuthStatusEnum,
} from "../../../../../context/AuthContext";
import { api } from "../../../../../utils/requests/Api";
import { TenantType } from "../../../../../types/companies/companies";
import { errorMessage } from "../../../../../utils/notifications/errorMessage";

// types

// helper variables
const ImgStyle = {
  cursor: "pointer",
};

export default function UserIdentifier() {
  // ** declare and define component state and variables
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const {
    changeAuthStatus,
    handleSetIdentifier,
    handleStoreGlobalId,
    handleStoreTenants,
    handleStoreSelectedTenant,
    handleSetPasswordFirstTime,
  } = useContext(AuthContext);

  // ** declare and define component helper methods
  const onSubmit = (data: FormData) => {
    //set identifier to context
    handleSetIdentifier(data.identifier);

    // declare helper variables
    const url = api`identifier-check`;
    const body = { identifier: data.identifier };

    const headers = {
      "Content-Type": "application/json",
    };

    type ResType = {
      msg?: string;
      global_id?: number;
      tenants?: TenantType[];
      can_set_pass?: boolean;
    };

    // start sending our request
    axios
      .post<ResType>(url, body, {
        headers: headers,
      })
      .then((response) => {
        // check error message is exist ?
        if (response.data?.msg) {
          // ** show error message
          errorMessage(response.data?.msg);
        } else if (response.data?.global_id) {
          // ** store global id of user
          handleStoreGlobalId(response.data?.global_id + "");

          // check user enter password pefor or not
          handleSetPasswordFirstTime(Boolean(response.data?.can_set_pass));

          if (response.data.tenants) {
            const tenants: TenantType[] = response.data.tenants;
            if (tenants.length > 1) {
              //allow users to choose corrct company
              handleStoreTenants(tenants);
              changeAuthStatus(AuthStatusEnum.ChooseTenant);
            } else if (tenants.length === 1) {
              handleStoreSelectedTenant(tenants[0]);
              if (response.data?.can_set_pass) {
                changeAuthStatus(AuthStatusEnum.ResetPassword);
                return;
              }
              //redirect to correct login page
              const lookUp = tenants?.[0]?.login_ways?.[0]?.lookup;
              switch (lookUp.name) {
                case "password":
                  changeAuthStatus(AuthStatusEnum.Password);
                  break;
                case "otp":
                  changeAuthStatus(AuthStatusEnum.EnterOTP);
                  break;
              }
            }
          }
        }
      })
      .catch(() => {
        errorMessage("خطا غير متوقع برجاء المحاولة فى وقت اخر");
      });
  };

  // ** component ui
  return (
    <Card className="flex flex-col" sx={{ width: "340px" }}>
      {/* card header */}
      <CardHeader
        title={
          <Typography
            variant="h4"
            fontWeight={600}
            fontSize={26}
            textAlign={"center"}
          >
            تسجيل الدخول
          </Typography>
        }
      ></CardHeader>
      {/* card content */}
      <CardContent className="p-6 sm:!p-12">
        <div className="flex flex-col gap-5">
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <TextField
              autoFocus
              fullWidth
              variant="standard"
              {...register("identifier")}
              label="ادخل رقم الجوال / البريد الالكتروني"
              error={!!errors.identifier}
              helperText={errors.identifier ? errors.identifier.message : ""}
            />

            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={!isValid}
            >
              التالي
            </Button>

            <Divider className="gap-3 text-textPrimary">أو</Divider>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              <img
                src={yahooImg}
                width={30}
                height={30}
                style={ImgStyle}
                alt="yahoo image"
              />
              <img
                src={gmailImg}
                alt="gmail img"
                width={30}
                height={30}
                style={ImgStyle}
              />
              <img
                src={nfazImg}
                alt="nafaz image"
                width={30}
                height={30}
                style={ImgStyle}
              />
            </Stack>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}

// Define schema with Zod
const schema = z.object({
  identifier: z.string().min(1, { message: "المعرف مطلوب" }),
});

type FormData = z.infer<typeof schema>;
