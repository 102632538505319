// MUI
import { Stack, Typography } from "@mui/material";

// Icons
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function CenterDialogErrorTitle() {
  return (
    <Stack
      width={"100%"}
      justifyContent={"start"}
      alignItems={"center"}
      direction={"row"}
      mx={4}
      px={2}
      spacing={2}
    >
      <ErrorOutlineIcon color="error" sx={{ fontSize: "40px" }} />
      <Typography
        variant="body2"
        fontSize={30}
        fontWeight={800}
        color={"error"}
      >
        خطا !
      </Typography>
    </Stack>
  );
}
