import classNames from "classnames";
import SignInEntryPoint from "./components/entryPoint";

export default function SignInMainViewsPoint() {
  return (
    <div className={classNames("ts-blank-layout", "is-full bs-full")}>
      <SignInEntryPoint />
    </div>
  );
}
