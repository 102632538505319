import axios from "axios";
import { api } from "../../requests/Api";

interface Root {}

export const deleteCompany = async (companiesIds: (string | number)[]) => {
  const data = (
    await axios.post<Root>(api`tenant/delete`, { ids: companiesIds })
  ).data;

  return data;
};
