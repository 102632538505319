import { useContext, useMemo } from "react";
import "./App.css";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import getLangCookie from "./utils/cookies/getLangCookie";
import axios from "axios";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnTranslation from "./translate/EnTranslation";
import ArTranslation from "./translate/ArTranslation";
import { LangContext } from "./context/LangContext";
import { CacheProvider } from "@emotion/react";
import RoutesComponent from "./routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

// TODO:: Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

// TODO::define current language
const currentLang = getLangCookie();
axios.defaults.headers.common["from"] = "website";
axios.defaults.headers.common["lang"] = currentLang === "ar" ? "ar" : "en";

// TODO::config react18inext
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: EnTranslation },
    ar: { translation: ArTranslation },
  },
  lng: currentLang,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});
const queryClient = new QueryClient();

function App() {
  // declare and define component state and variables
  const langContext = useContext(LangContext);
  const lang = langContext.lang();

  const render = useMemo(() => {
    switch (lang) {
      case "ar":
        return (
          <QueryClientProvider client={queryClient}>
            <CacheProvider value={cacheRtl}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RoutesComponent />
              </LocalizationProvider>
            </CacheProvider>{" "}
          </QueryClientProvider>
        );
      default:
        return <RoutesComponent />;
    }
  }, [lang]);

  // return component ui.
  return render;
}

export default App;
