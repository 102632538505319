// Types
import type { ImgHTMLAttributes } from "react";

// Hooks
import { useContext } from "react";

// MUI
import { Stack, Typography } from "@mui/material";

// Media
import constraxLogo from "../../../../../assets/images/logos/constrix.png";

const LogoImage = (props: ImgHTMLAttributes<HTMLImageElement>) => (
  <img {...props} style={{ height: 82, ...props.style }} alt="company logo" />
);

export default function PageHeader() {
  return (
    <Stack
      direction="column"
      gap={2}
      minHeight={"150px"}
      alignItems="center"
      justifyContent={"center"}
    >
      <div>
        <LogoImage src={constraxLogo} />
      </div>
      <Typography variant="h4" fontWeight={700} fontSize={22} color="#fff">
        لوحة التحكم
      </Typography>
    </Stack>
  );
}
