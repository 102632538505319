import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChildrenPropsType } from "../types/GlobalTypes";
import { isAuthenticated } from "../utils/requests/isAuthenticated";
import getXtenantCookie from "../utils/cookies/getXtenantCookie";
import getTokenCookie from "../utils/cookies/getTokenCookie";
import axios from "axios";

const AuthGuard: React.FC<ChildrenPropsType> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/auth/signin");
    }
    axios.defaults.headers.common["X-Tenant"] = getXtenantCookie();
    axios.defaults.headers.common.Authorization = `Bearer ${getTokenCookie()}`;
  }, [navigate]);

  return <>{isAuthenticated() ? children : null}</>;
};

export default AuthGuard;
