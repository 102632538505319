import React, { useState, useEffect } from "react";
import { Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import getLangCookie from "../../utils/cookies/getLangCookie";

const GoToTopButton: React.FC = () => {
  const lang = getLangCookie();
  const [visible, setVisible] = useState<boolean>(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    
    if (offset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {visible && (
        <Fab
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "3.5%",
            [lang === "en" ? "right" : "left"]: "6%",
          }}
          size="medium"
          color="primary"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </>
  );
};

export default GoToTopButton;
