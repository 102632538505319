import { UserIdentifiersEnum } from "../../types/users/UserRoles";

export const userIdentifiersWays = [
  {
    id: "ui-1",
    label: "هوية وطنية",
    identifierLabel: "رقم الهوية الوطنية",
    value: UserIdentifiersEnum.NationalIdentity,
  },
  {
    id: "ui-2",
    label: "جواز سفر",
    identifierLabel: "رقم جواز سفر",
    value: UserIdentifiersEnum.Passport,
  },
  {
    id: "ui-3",
    label: "اقامة",
    identifierLabel: "رقم الاقامة",
    value: UserIdentifiersEnum.Iqama,
  },
  {
    id: "ui-4",
    label: "رقم حدود",
    identifierLabel: "رقم حدود",
    value: UserIdentifiersEnum.BorderNumber,
  },
];
