// Mui
import { Grid, Stack, Typography } from "@mui/material";

// Types
import { TenantType } from "../../../../../types/companies/companies";
import { useContext } from "react";
import {
  AuthContext,
  AuthStatusEnum,
} from "../../../../../context/AuthContext";

export default function CompanyCard(props: PropsType) {
  // ** declare and define component state and variables
  const { tenant } = props;
  const { handleStoreSelectedTenant, setPasswordFirstTime, changeAuthStatus } =
    useContext(AuthContext);

  // ** declare and define component helper methods
  const handleClick = () => {
    // store selected tenant
    handleStoreSelectedTenant(tenant);

    if (setPasswordFirstTime) {
      changeAuthStatus(AuthStatusEnum.ResetPassword);
      return;
    }

    switch (props.tenant.login_ways?.[0]?.lookup?.name) {
      case "otp":
        changeAuthStatus(AuthStatusEnum.EnterOTP);
        break;
      case "password":
        changeAuthStatus(AuthStatusEnum.Password);
        break;
    }
  };

  return (
    <Grid
      item
      xs={3.7}
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "12px",
        padding: "1.3rem",
        margin: "0.2rem",
        ":hover": {
          bgcolor: "#8080801c",
        },
      }}
      onClick={handleClick}
    >
      <Stack alignContent={"center"} justifyContent={"center"} spacing={2}>
        <Stack
          width={"100px"}
          height={"100px"}
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={"#463361"}
          borderRadius={"13px"}
        >
          <img
            src={tenant?.media?.[0]?.original_url ?? ""}
            alt="tenant image"
            width={60}
            height={60}
          />
        </Stack>
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          textAlign={"center"}
        >
          New Vision
        </Typography>
      </Stack>
    </Grid>
  );
}

type PropsType = {
  tenant: TenantType;
};
