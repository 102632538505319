import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { validationType } from '../types/GlobalTypes'
import { api } from '../utils/requests/Api'



const fetchData = async () => {
  const Response = await axios.get<{ validations: validationType[] }>(api`lookup/validations`)

  return Response.data.validations
}

export default function useValidationData() {
  return useQuery({
    queryKey: [`validations-data`],
    queryFn: fetchData
  })
}
