// MUI & Styles
import { Divider, IconButton, List, Stack, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";
// Icons
import { DRAWER_WIDTH } from "../../../constants/layout/drawerWidth";
import { SideBarListItems } from "../../../constants/layout/sidebarListItems";
import SideBarListItem from "./SideBarListItem";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

// media
import newVisionLogo from "../../../assets/images/logos/new-vision.png";
import getLangCookie from "../../../utils/cookies/getLangCookie";
import NavbarFooter from "./SidebarFooter";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  height: "100%",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MianSideBar(props: SidebarPropsType) {
  // ** declare and define component state and variables
  const lang = getLangCookie();
  const { open, setOpen } = props;

  // ** declare and define component helper methods
  const handleDrawerClose = () => setOpen(false);

  // ** return component ui.
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Stack mx={2} direction={"row"} alignItems={"center"} spacing={2}>
          <img src={newVisionLogo} width={40} height={40} alt="company logo" />
          <Typography variant="body2" fontSize={20} fontWeight={600}>
            New Vision
          </Typography>
        </Stack>

        <IconButton onClick={handleDrawerClose}>
          {lang === "en" ? (
            <KeyboardDoubleArrowLeftIcon />
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Stack alignItems={"start"} justifyContent={"space-between"} flexGrow={1}>
        <List>
          {SideBarListItems?.map((item) => (
            <SideBarListItem key={item.id} open={open} item={item} />
          ))}
        </List>
        <NavbarFooter />
      </Stack>
    </Drawer>
  );
}

type SidebarPropsType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
