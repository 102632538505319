import { createTheme } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#F42588",
    },
    secondary: { main: "#18003A" },
    error: { main: "#FF4747" },
    warning: { main: "#d88b06" },
    info: { main: "#18003A" },
    success: { main: "#18CB5F" },
    background: {
      default: "#140F35",
      paper: "#1A153E",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#A59ABE",
    },
  },
});

export default darkTheme;
