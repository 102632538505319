// types
import { SideBarListItemType } from "../../types/LayoutTypes";

// icons
import HomeIcon from "@mui/icons-material/Home";
import WidgetsIcon from "@mui/icons-material/Widgets";
import GroupsIcon from '@mui/icons-material/Groups';

export const SideBarListItems: SideBarListItemType[] = [
  {
    id: "SBLI01",
    text: "Home Page",
    icon: <HomeIcon />,
    href: "/",
  },
  {
    id: "SBLI02",
    text: "Test Page",
    icon: <HomeIcon />,
    children: [
      {
        id: "SBLI02.1",
        text: "test Page",
        icon: <HomeIcon />,
        href: "/test",
      },
      {
        id: "SBLI02.2",
        text: "Home Page",
        icon: <HomeIcon />,
        href: "/",
      },
    ],
  },
  {
    id: "SBLI03",
    text: "المستخدمين",
    icon: <GroupsIcon />,
    href: "/users",
  },
  {
    id: "SBLI004",
    text: "الشركات",
    icon: <WidgetsIcon />,
    href: "/companies",
  },
];
