import axios from "axios";
import { api } from "../../requests/Api";

interface Root {
  url: string;
}

export const getExportCompaniesUrl = async (
  params: GetExportCompaniesUrlParams
) => {
  const data = (await axios.get<Root>(api`tenant/export`, { params })).data;

  return data;
};

export type GetExportCompaniesUrlParams = {
  ids: (string | number)[];
};
