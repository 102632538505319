import React, { forwardRef } from "react";
import { Dialog, DialogTitle, DialogContent, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import styled from "@emotion/styled";
import getLangCookie from "../../../utils/cookies/getLangCookie";

// Transition
// AR
const TransitionAR = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction={"right"} ref={ref} {...props} />;
});
// EN
const TransitionEn = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction={"left"} ref={ref} {...props} />;
});

// styled dialog
// AR
const FullHeightDialogAr = styled(Dialog)({
  "& .MuiDialog-paper": {
    position: "fixed",
    right: 0,
    top: 0,
    bottom: 0,
    width: "300px",
    maxWidth: "300px",
    margin: 0,
    borderRadius: 0,
  },
});
// EN
const FullHeightDialogEn = styled(Dialog)({
  "& .MuiDialog-paper": {
    position: "fixed",
    right: 0,
    top: 0,
    bottom: 0,
    width: "300px",
    maxWidth: "300px",
    margin: 0,
    borderRadius: 0,
  },
});

export default function LeftSideDialog(props: LeftSideDialogProps) {
  const lang = getLangCookie();
  const { open, setOpen, title, dialogContent } = props;

  const handleClose = () => {
    setOpen(false);
  };

  if (lang === "ar")
    return (
      <FullHeightDialogAr
        open={open}
        TransitionComponent={TransitionAR}
        keepMounted
        onClose={handleClose}
        aria-describedby="slide-in-dialog-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
      </FullHeightDialogAr>
    );

  return (
    <FullHeightDialogEn
      open={open}
      TransitionComponent={TransitionEn}
      keepMounted
      onClose={handleClose}
      aria-describedby="slide-in-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
    </FullHeightDialogEn>
  );
}

type LeftSideDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: React.ReactNode;
  dialogContent: React.ReactNode;
};
